import { Article, Page, Section } from '@cg/module-frontend/src/components';

import { ExperienceSummary } from '~/components/experiences';
import { ExperienceWithTickets } from '~/generated/models/ExperienceWithTickets';
import DashboardSummary from './components/DashboardSummary';
import { Host } from '~/generated/models/Host.ts';

type Props = {
  host: Host;
  experiences: ExperienceWithTickets[];
  series: ExperienceWithTickets[];
};

function DashboardPage({ experiences, series, host }: Props) {
  const renderExperiences = (list: ExperienceWithTickets[]) => {
    if (list.length === 0) {
      return <Article className="text-center">Nothing yet</Article>;
    }

    return (
      <>
        {list.map((experience) => {
          return (
            <ExperienceSummary
              experience={experience}
              key={experience.id.getValue()}
            />
          );
        })}
      </>
    );
  };

  return (
    <Page>
      <Section>
        <h2>Overview</h2>
        <DashboardSummary host={host} />
      </Section>

      <Section>
        <h3>Upcoming Experiences</h3>
        {renderExperiences(experiences)}
      </Section>

      <Section>
        <h3>Upcoming Runs</h3>
        {renderExperiences(series)}
      </Section>
    </Page>
  );
}

export default DashboardPage;
