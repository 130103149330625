import { Link } from 'react-router-dom';
import {
  DashboardIcon,
  DeveloperIcon,
  ExperienceOutlineIcon,
  ExperienceSeriesIcon,
  PaymentSettingsIcon,
  PlusIcon,
  PreferencesIcon,
  ProfileIcon,
  SettingsIcon,
  UserGroupOutlineIcon,
  UsersOutlineIcon,
} from '@cg/module-frontend/src/icons';
import {
  LeftNavigation,
  Nav,
  Button,
  Img,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { featureFlagConstants } from '@cg/common/src/constants';
import CreateExperience from './CreateExperience';

const navs: Nav[] = [
  {
    to: '/dashboard/home',
    icon: (active) => (
      <DashboardIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Dashboard',
  },
  {
    to: '/dashboard/experiences',
    icon: (active) => (
      <ExperienceOutlineIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Experiences',
  },
  {
    to: '/dashboard/series',
    featureFlags: [featureFlagConstants.PLAYGROUND_SERIES],
    icon: (active) => (
      <ExperienceSeriesIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Runs',
  },
  {
    to: '/dashboard/guests',
    icon: (active) => (
      <UserGroupOutlineIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Guests',
  },
  {
    to: '/dashboard/profile',
    icon: (active) => (
      <ProfileIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Profile',
  },
  {
    to: '/dashboard/settings',
    icon: (active) => (
      <SettingsIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Settings',
    children: [
      {
        to: '/dashboard/settings/preferences',
        icon: (active) => (
          <PreferencesIcon
            className={classNames('size-6', {
              'text-primary': active,
              'text-black': !active,
            })}
          />
        ),
        title: 'Preferences',
      },
      {
        to: '/dashboard/settings/payments',
        icon: (active) => (
          <PaymentSettingsIcon
            className={classNames('size-6', {
              'text-primary': active,
              'text-black': !active,
            })}
          />
        ),
        title: 'Payments',
      },
      {
        to: '/dashboard/settings/organizers',
        icon: (active) => (
          <UsersOutlineIcon
            className={classNames('size-6', {
              'text-primary': active,
              'text-black': !active,
            })}
          />
        ),
        title: 'Organizers',
      },
      {
        to: '/dashboard/settings/developer',
        icon: (active) => (
          <DeveloperIcon
            className={classNames('size-6', {
              'text-primary': active,
              'text-black': !active,
            })}
          />
        ),
        title: 'Developer',
      },
    ],
  },
];

function LeftNav() {
  const [create, setCreate] = useState(false);

  return (
    <>
      {create && <CreateExperience onClose={() => setCreate(false)} />}

      <LeftNavigation
        actionBtn={
          <Button
            color="primary"
            className="writespace-nowrap"
            onClick={() => setCreate(true)}
          >
            <PlusIcon className="size-6 mr-1" />
            New Experience
          </Button>
        }
        logo={
          <Link to="/dashboard">
            <Img
              src="https://assets.joincommonground.com/branding/logo-purple-cropped.svg"
              alt="logo"
              className="h-8"
            />
          </Link>
        }
        topNav={
          <div className="flex px-6">
            <div className="flex flex-col">
              <Link
                className="flex gap-2.5 text-base font-medium leading-4 text-primary whitespace-nowrap"
                to="/dashboard"
              >
                <Img
                  src="https://assets.joincommonground.com/branding/pg-logoword-purple.svg"
                  alt="logo"
                  className="h-8"
                />
              </Link>
            </div>
          </div>
        }
        navs={navs}
      />
    </>
  );
}

export { LeftNav };
