import { useNavigate } from 'react-router';
import { Article, Button, Img } from '@cg/module-frontend/src/components';
import { ClipboardIcon } from '@cg/module-frontend/src/icons';
import { useScreenSize } from '@cg/module-frontend/src/hooks';
import { ExperienceWithTickets } from '~/generated/models/ExperienceWithTickets';
import ExperienceMetrics from '~/components/experiences/ExperienceMetrics.tsx';

interface ExperienceSummaryProps {
  experience: ExperienceWithTickets;
}

function ExperienceSummary({ experience }: ExperienceSummaryProps) {
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return (
      <Article>
        <div className="flex flex-row gap-5">
          <div className="w-36 h-36 flex-shrink-0">
            <Img
              src={
                experience.bannerUrl ||
                'https://assets.joincommonground.com/site/images/pg-no-image.webp'
              }
              alt="Challenge thumbnail"
              className="aspect-square object-fill rounded object-center w-full h-full"
            />
          </div>
          <div className="flex flex-col justify-center flex-grow">
            <h5 className="font-bold text-black">{experience.title}</h5>
          </div>
        </div>
        <div className="flex flex-row mt-3">
          <ExperienceMetrics
            experience={experience}
            tiers={experience.tiers}
            tickets={experience.tickets}
          />
        </div>
        <div className="flex flex-row mt-3 w-full">
          <div className="flex flex-col justify-center w-full px-4 text-primary rounded bg-grey">
            <div className="flex flex-col justify-center my-2 text-center">
              <div className="justify-center px-2 font-semibold">
                {experience.status}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5 mt-3 w-full">
          <Button
            color="secondary"
            onClick={() => navigate(`/dashboard/experiences/${experience.id}`)}
            className="flex flex-col justify-center w-full"
            icon={<ClipboardIcon className="size-6 mr-2" />}
          >
            Details
          </Button>
        </div>
      </Article>
    );
  }

  return (
    <Article className="p-3 bg-white rounded mt-5">
      <div className="flex flex-row gap-5">
        <div className="flex gap-5 justify-between w-auto">
          <div className="w-36 h-36">
            <Img
              src={
                experience.bannerUrl ||
                'https://assets.joincommonground.com/site/images/pg-no-image.webp'
              }
              alt="Challenge thumbnail"
              className="aspect-square object-fill rounded object-center w-full h-full"
            />
          </div>

          <div className="flex flex-col">
            <h5 className="font-bold text-black">{experience.title}</h5>
            <div className="flex-grow" />
            <ExperienceMetrics
              experience={experience}
              tiers={experience.tiers}
              tickets={experience.tickets}
            />
          </div>
        </div>
        <div className="flex flex-grow" />
        <div className="flex gap-5 my-auto">
          <Button
            color="secondary"
            onClick={() => navigate(`/dashboard/experiences/${experience.id}`)}
            className="flex flex-col justify-center w-fit"
            icon={<ClipboardIcon className="size-6 mr-2" />}
          >
            Details
          </Button>
        </div>
      </div>
    </Article>
  );
}

export default ExperienceSummary;
