import { Loader } from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/module-frontend';
import { useEffect } from 'react';

import { listExperiencesFilteredHook } from '~/generated/clients/playground/hosts/experiences/PlaygroundHostsExperiences.hooks';
import { ExperienceStatus } from '~/generated/models/ExperienceStatus';
import DashboardPage from './DashboardPage';
import { ExperienceType } from '~/generated/models/ExperienceType.ts';

function Dashboard() {
  const { self } = useAuth();
  const {
    data: list,
    calling: fetchingExperiences,
    call: fetchExperiences,
  } = listExperiencesFilteredHook(false);

  useEffect(() => {
    if (!self?.user || !self?.host) {
      return;
    }

    if (!list && !fetchingExperiences) {
      fetchExperiences({
        ids: {
          hostId: self.host.id,
        },
        filters: {
          status: ExperienceStatus.Published,
          upcomingOnly: true,
          pageSize: 20,
        },
      });
    }
  }, [self]);

  const experiences =
    list?.result?.filter((e) => e.type === ExperienceType.OneTime) ?? [];
  const series =
    list?.result?.filter((e) => e.type === ExperienceType.RunClub) ?? [];

  return (
    <Loader loading={fetchingExperiences}>
      {self?.host && (
        <DashboardPage
          experiences={experiences}
          series={series}
          host={self.host}
        />
      )}
    </Loader>
  );
}

export default Dashboard;
